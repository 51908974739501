import React, { useState } from 'react';
import classNames from 'classnames/bind';
//
import * as styles from './FAQItem.module.scss';
import caret from './../../images/caret.png';

const cx = classNames.bind(styles);

const FAQItem = ({ question, answer }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={cx('faqItem', { open: open })}>
      <button className={cx('question')} onClick={() => setOpen(!open)}>
        {question}
        <img
          className={cx('caret', { open: open })}
          src={caret}
          alt={open ? 'close' : 'open'}
        />
      </button>
      <div className={cx('answer')} aria-expanded={open}>
        <p dangerouslySetInnerHTML={{ __html: answer }}></p>
      </div>
    </div>
  );
};

export default FAQItem;
