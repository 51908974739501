import React, { useState } from 'react';
import classNames from 'classnames/bind';
//
import * as styles from './Navigation.module.scss';
import logo from '../../images/Trailblazer_Logo_Black.svg';
import menu from './../../images/menu.svg';
import close from './../../images/close.svg';
import Button from './../Button/Button';

const cx = classNames.bind(styles);

const menuItems = [
  {
    name: 'About',
    sectionID: 'about',
  },
  {
    name: 'Our Companies',
    sectionID: 'companies',
  },
  {
    name: 'Our Partners',
    sectionID: 'partners',
  },
  {
    name: 'FAQs',
    sectionID: 'faqs',
  },
  {
    name: 'Connect',
    type: 'button',
    link: 'https://rga-ventures.typeform.com/TVSConnect',
    width: '115px',
    mobileFullWidth: true,
  },
];

const Navigation = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      menuOpen ? handleMenuClose() : handleMenuOpen();
    }
  };

  const handleClickMenuItem = (event, sectionID) => {
    event.preventDefault();

    handleMenuClose();

    if (sectionID) {
      const section = document.getElementById(sectionID);
      const navHeight = window.innerWidth > 768 ? 80 : 60;
      const sectionTop = section.offsetTop - navHeight;

      window.scroll({ top: sectionTop, behavior: 'smooth' });
    } else {
      window.scroll({ top: 0, behavior: 'smooth' });
    }
  };

  const renderMenuItems = () => {
    return menuItems.map((menuItem, index) => {
      if (menuItem.type === 'button') {
        return (
          <li key={index} className={cx('menuItem', 'cta')}>
            <Button
              link={menuItem.link}
              width={menuItem.width}
              mobileFullWidth={menuItem.mobileFullWidth}
            >
              {menuItem.name}
            </Button>
          </li>
        );
      } else {
        return (
          <li key={index} className={cx('menuItem')}>
            <a
              href='/'
              onClick={(event) =>
                handleClickMenuItem(event, menuItem.sectionID)
              }
            >
              {menuItem.name}
            </a>
          </li>
        );
      }
    });
  };

  return (
    <nav className={cx('navigation')} role='navigation'>
      <div className={cx('navContainer')}>
        <a
          className={cx('navLogo')}
          href='/'
          onClick={(event) => handleClickMenuItem(event)}
        >
          <img src={logo} alt='Trailblazer Venture Studio Logo' />
        </a>
        <div
          className={cx('navControl')}
          role='button'
          tabIndex={0}
          onClick={menuOpen ? handleMenuClose : handleMenuOpen}
          onKeyDown={(event) => handleKeyDown(event)}
        >
          <img src={menuOpen ? close : menu} alt='Menu' />
        </div>
        <div className={cx('menuContainer', { open: menuOpen })}>
          <ul className={cx('menuItems')}>
            <div className={cx('menuItem', 'jumpToSection')}>
              Jump to section:
            </div>
            {renderMenuItems()}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
