import React from 'react';
import classNames from 'classnames/bind';
//
import * as styles from './CopyBlock.module.scss';
import Button from './../Button/Button';

const cx = classNames.bind(styles);

const CopyBlock = () => {
  const renderApplyButton = () => {
    return (
      <Button link='https://rga-ventures.typeform.com/TVSConnect'>
        Connect With Us
      </Button>
    );
  };

  return (
    <section className={cx('copyBlock')} id='about'>
      <div className={cx('copyContainer')}>
        <div className={cx('left')}>
          <h1>The first venture studio to focus on women and sports.</h1>
          <div className={cx('description')}>
            <p>
              The Trailblazer Venture Studio focuses on the biggest growth
              opportunity in sports: women.
            </p>
            <p>
              We partner with entrepreneurs and companies—from early-stage
              startups to later-stage businesses—that put women leaders,
              athletes, and fans first.
            </p>
            <p>
              Collaboration lies at the heart of this work. Let’s build
              together.
            </p>
          </div>
          <div className={cx('cta', 'desktop')}>{renderApplyButton()}</div>
        </div>
        <div className={cx('right')}>
          <p className={cx('bold')}>Our Focus</p>
          <ul>
            <li>Media, culture, and storytelling</li>
            <li>Health, fitness, and well-being</li>
            <li>Training and performance</li>
            <li>Sponsorship and compensation</li>
            <li>Retail and commerce</li>
            <li>Fan experience</li>
            <li>Esports and gaming</li>
          </ul>
        </div>
        <div className={cx('cta', 'mobile')}>{renderApplyButton()}</div>
      </div>
    </section>
  );
};

export default CopyBlock;
