import React from 'react';
import classNames from 'classnames/bind';
import * as styles from './CompaniesCard.module.scss';

const cx = classNames.bind(styles);

const CompaniesCard = ({ partner }) => {
  const { name, logo, logoWidth, description, link } = partner;

  return (
    <div className={cx('companyCard')}>
      <div className={cx('card')}>
        {logo ? <a href={link} target="_blank"><img src={logo} alt={name} style={{ width: logoWidth }} /></a> : name}
      </div>
      {name && link && <a className={cx('name')} href={link} target="_blank">{name}</a>}
      {description && <p className={cx('description')}>{description}</p>}
    </div>
  );
};

export default CompaniesCard;
