// extracted by mini-css-extract-plugin
export var cta = "Navigation-module--cta--weev3";
export var jumpToSection = "Navigation-module--jump-to-section--PWjMG";
export var menuContainer = "Navigation-module--menu-container--gSwum";
export var menuItem = "Navigation-module--menu-item--lFN80";
export var menuItems = "Navigation-module--menu-items--Q4RGU";
export var navContainer = "Navigation-module--nav-container--p+V2L";
export var navControl = "Navigation-module--nav-control--dbWqY";
export var navLogo = "Navigation-module--nav-logo--nAjlI";
export var navigation = "Navigation-module--navigation--6cMXu";
export var open = "Navigation-module--open--BMRsv";