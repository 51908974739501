import React from 'react';
import classNames from 'classnames/bind';
import * as styles from './Header.module.scss';
import logo from '../../images/Trailblazer_Logo_Black.svg';
import rippleYellow from './../../images/hero-ripple-yellow.svg';

const cx = classNames.bind(styles);

const Header = () => {
  return (
    <section className={cx('header')}>
      <div className={cx('backgroundRipple')}>
        <img src={rippleYellow} alt='' />
      </div>
      <div className={cx('backgroundRippleTop')}>
        <img src={rippleYellow} alt='' />
      </div>
      <div className={cx('content')}>
        <div className={cx('brand')}>
          <img src={logo} alt='Trailblazer Venture Studio Logo' />
        </div>
        <div className={cx('subhead')}>
          Women
          <br />
          and Sports
        </div>
      </div>
    </section>
  );
};

export default Header;
