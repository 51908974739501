import React from 'react';
import classNames from 'classnames/bind';
//
import * as styles from './FAQ.module.scss';
import FaqItem from './FAQItem';
import Button from './../Button/Button';
import rippleGreen from './../../images/ripple-green.png';

const cx = classNames.bind(styles);

const faqData = [
  {
    question: 'Are applications for 2022 still open?',
    answer:
      'Applications are currently closed but we encourage you to keep in touch as there may be future opportunities. Click <a href="https://rga-ventures.typeform.com/TVSConnect" target="_blank" rel="noopener noreferrer">here</a> to connect with us, and read on for more specifics about the program.',
  },
  {
    question: 'What is the time commitment?',
    answer:
      'The Trailblazer Venture Studio will launch with a 12-week program that will require 3-5 hours of your time each week. Many of us have been entrepreneurs ourselves and understand what it takes to run a business. We are conscious of your time, which is why our work together involves us plugging into your priorities or weekly meetings focusing on the areas where you need help, whether that’s strategic business development, brand strategy, marketing, or setting up innovation pilots. The hours you spend with us are about building your business and achieving your goals.',
  },
  {
    question: 'Is the program virtual or in-person?',
    answer:
      'Most of the program will be virtual, with opportunities to be in person in both NYC and LA.',
  },
  {
    question: 'Is my company at the right stage to apply?',
    answer:
      'This Studio is about propelling dynamic women leaders and innovation that benefits women, rather than about the stage of the company. If you’re focusing on new opportunities for women leaders, athletes and fans, or fans of women’s sports, you are a potential fit for us.',
  },
  {
    question: 'Do I have to identify as a woman founder to apply?',
    answer:
      'No. We are interested in how the goals and actions of your business speak to women, benefit women athletes and fans, or appeal to fans of all genders who love women’s sports. If your company is gender-inclusive and committed to diversity in leadership, if your product services women as much as it does any other gender, or if your product is built specifically for women, we encourage you to apply.',
  },
  {
    question: 'What kind of financial investment will my company receive?',
    answer:
      'Our approach is different from an incubator or accelerator. Some companies entering our program may need growth capital; others may already be well-capitalized. Each situation is unique, but our Studio partners have the capacity to make significant, stage-agnostic investments if there is a good fit. In most instances, Elysian Park Ventures and R/GA Ventures will provide the investment capital. In special situations, there is the possibility of more funding from BJKE Enterprises.',
  },
  {
    question: 'What is creative capital?',
    answer:
      'It’s the chance to work with R/GA’s world-class strategists, technologists, creatives, and consultants in either brand, visual, or verbal design services.  The Studio will also provide participating companies access to Robin, an Elysian Park Ventures owned business that can support your creative work after the R/GA services end.',
  },
  {
    question: 'Will it cost me anything to participate in the program?',
    answer:
      'We ask for an equity warrant in exchange for services provided. This warrant covers the costs of running the Studio, as well as aligning BJKE, Elysian Park Ventures, and R/GA with you for the long-term. These warrants are common stock that vests over a period of time and are priced at the 409A, which means we win when you win. We strongly believe in the value of the program, and to back up that belief, you’re able to cancel warrants at any time.',
  },
  {
    question: 'Can I be a participant in other accelerator programs?',
    answer:
      'Participation in other accelerator programs will not exclude you, but we prefer to work with companies that can devote the time required to pursue the full range of opportunities delivered by the Studio.',
  },
  {
    question: 'What do I need to prepare before submitting my application?',
    answer:
      '<a href="https://docs.google.com/document/d/1RMHr4SDS9qu4o6W2wpXLh1ZUxUbiHglFnWZhf-2p9tY/edit?usp=sharing" target="_blank" rel="noopener noreferrer">Here</a> is where you can preview all the application questions. The video submission is optional.',
  },
  {
    question: 'What does the selection process entail?',
    answer:
      'Once you submit your application, we’ll reach out directly if we have more questions or for a formal pitch. This outreach is ongoing, so we encourage you to apply early. Decisions will be made by July 1, 2022.',
  },
];

const FAQ = () => {
  const renderFaqItems = () => {
    return faqData.map((item, index) => {
      return (
        <FaqItem key={index} question={item.question} answer={item.answer} />
      );
    });
  };

  return (
    <section className={cx('faq')} id='faqs'>
      <div className={cx('faqContainer')}>
        <div className={cx('left')}>
          <h2>FAQ</h2>
          <h3>
            Have questions? &nbsp;
            <br />
            We have answers.
          </h3>
        </div>
        <div className={cx('right')}>
          <div className={cx('faqWrapper')}>{renderFaqItems()}</div>
          <div className={cx('faqFooter')}>
            <p>
              Additional questions? Email us at{' '}
              <a href='mailto: trailblazerVS@rga.com'>trailblazerVS@rga.com</a>
            </p>
            <Button link='https://rga-ventures.typeform.com/TVSConnect'>
              Connect With Us
            </Button>
          </div>
        </div>
      </div>
      <div className={cx('bgImage')}>
        <img src={rippleGreen} alt='' />
      </div>
    </section>
  );
};

export default FAQ;
