import React from 'react';
import classNames from 'classnames/bind';
//
import * as styles from './PartnershipsBanner.module.scss';
import BJKELogo from './../../images/partners/bjke_logo_bw.svg';
import EPVLogo from './../../images/partners/epv_logo_bw.svg';
import LADodgersLogo from './../../images/partners/la_dodgers_logo_bw.svg';
import RGAVenturesLogo from './../../images/partners/rga_ventures_logo_bw.svg';

const cx = classNames.bind(styles);

const PartnershipsBanner = () => {
  return (
    <section className={cx('partnershipsBanner')}>
      <div className={cx('content')}>
        <div className={cx('header')}>
          <h3>In partnership with</h3>
        </div>
        <div className={cx('logos')}>
          <img
            className={cx('bjke')}
            src={BJKELogo}
            alt='Billie Jean King Enterprises'
          />
          <img className={cx('epv')} src={EPVLogo} alt='Elysian Park' />
          <img
            className={cx('laDodgers')}
            src={LADodgersLogo}
            alt='LA Dodgers'
          />
          <img
            className={cx('rgaVentures')}
            src={RGAVenturesLogo}
            alt='R/GA Ventures'
          />
        </div>
      </div>
    </section>
  );
};

export default PartnershipsBanner;
