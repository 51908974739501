import React from 'react';
import classNames from 'classnames/bind';
import * as styles from './Button.module.scss';

const cx = classNames.bind(styles);

const Button = ({ link, width, mobileFullWidth, children }) => {
  return (
    <a
      className={cx('button', { mobileFullWidth: mobileFullWidth })}
      href={link}
      target='_blank'
      rel='noopener noreferrer'
      style={{
        width: width,
      }}
    >
      <div>{children}</div>
    </a>
  );
};

export default Button;
