import * as React from 'react';
import Navigation from '../components/Navigation/Navigation';
import Header from '../components/Header/Header';
import Social from '../components/Social/Social';
import PartnershipsBanner from '../components/PartnershipsBanner/PartnershipsBanner';
import CopyBlock from '../components/CopyBlock/CopyBlock';
import Banner from '../components/Banner/Banner';
import Companies from '../components/Companies/Companies';
import Partners from '../components/Partners/Partners';
// import AdvisoryCouncil from '../components/AdvisoryCouncil/AdvisoryCouncil';
import Faq from '../components/FAQ/FAQ';
import Seo from '../components/SEO/Seo';

// markup
const IndexPage = () => {
  return (
    <main>
      <Seo />
      <Navigation />
      <Header />
      <PartnershipsBanner />
      <CopyBlock />
      <Banner />
      <Companies />
      <Partners />
      {/* <AdvisoryCouncil /> */}
      <Faq />
      <Social />
    </main>
  );
};

export default IndexPage;
