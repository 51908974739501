import React from "react"
import { Helmet } from "react-helmet"
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from "gatsby"


const SEO = ({ keywords }) => {
    const { site, featuredImage } = useStaticQuery(graphql`
    query SeoMetaData {
      site {
        siteMetadata {
          title
          description
          siteUrl
          image
          og {
            siteName
            twitterCreator
          }
        }
      }
      featuredImage: file(
          absolutePath: { glob: "**/src/images/featured-image.png" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 1200)
          }
        }
    }
  `)

    // determine the featured image from props
    const ogImage = featuredImage?.childImageSharp?.gatsbyImageData;

    // determine title and description
    const title = site?.siteMetadata?.title;
    const description = site?.siteMetadata?.description;

    // Use the location hook to get current page URL
    const location = useLocation();

    const metas = [
        // basic seo
        {
            name: 'description',
            content: description,
        },
        {
            name: 'og:image',
            content: `${site?.siteMetadata?.siteUrl}${ogImage.images.fallback.src}`,
        },
        {
            name: 'image',
            property: 'og:image',
            content: `${site?.siteMetadata?.siteUrl}${ogImage.images.fallback.src}`,
        },
        {
            name: 'og:image:width',
            content: `${ogImage.width}`,
        },
        {
            name: 'og:image:height',
            content: `${ogImage.height}`,
        },
        {
            name: 'og:type',
            content: 'website',
        },
        {
            name: 'og:title',
            content: title,
        },
        {
            name: 'og:description',
            content: description,
        },
        {
            name: 'og:site_name',
            content: site?.siteMetadata?.og?.siteName,
        },
        {
            name: 'og:url',
            content: `${site?.siteMetadata?.siteUrl}${location.pathname}`,
        },
        {
            name: 'twitter:card',
            content: 'summary_large_image',
        },
        {
            name: 'twitter:description',
            content: description,
        },
        {
            name: 'twitter:title',
            content: title,
        },
        {
            name: 'twitter:image',
            content: `${site?.siteMetadata?.siteUrl}${ogImage.images.fallback.src}`,
        },
        {
            name: 'twitter:creator',
            content: site?.siteMetadata?.og?.twitterCreator,
        },
    ];

    // If we have keywords, then add it
    if (keywords) {
        metas.push({
            name: 'keywords',
            content: keywords,
        });
    }

    return (
        <Helmet>
            <html lang="en" />
            <meta charSet="utf-8" />
            <title>{title}</title>
            {metas.map(meta => {
                if (meta.property) {
                    return <meta key={meta.name} name={meta.name} content={meta.content} property={meta.property} />
                } else {
                    return <meta key={meta.name} name={meta.name} content={meta.content} />
                }
            }
            )}
        </Helmet>
    )
}
export default SEO