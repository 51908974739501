import React from 'react';
import classNames from 'classnames/bind';
import * as styles from './Social.module.scss';

const cx = classNames.bind(styles);

const Social = () => {
  return (
    <section className={cx('social')} id="social">
      <h2>
        Follow us on Twitter:{' '}
        <a
          href='https://twitter.com/trailblazer_vs'
          target='_blank'
          rel='noopener noreferrer'
        >
          @trailblazer_vs
        </a>
      </h2>
    </section>
  );
};

export default Social;
