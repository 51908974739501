import React from 'react';
import classNames from 'classnames/bind';

import CompaniesCard from './CompaniesCard';

import curastoryLogo from './../../images/companies/curastory.png';
import elysianParkLogo from './../../images/companies/epv_blue.png';
import forteLogo from './../../images/companies/forte.png';
import gistLogo from './../../images/companies/gist.png';
import idaLogo from './../../images/companies/ida.png';
import sportsboxLogo from './../../images/companies/sportsbox.png';
import galaxyRacerLogo from './../../images/companies/her-galaxy-racer.png';
import togethxrLogo from './../../images/companies/togethxr.png';
import lovbLogo from './../../images/companies/lovb.png';

import * as styles from './Companies.module.scss';

const cx = classNames.bind(styles);

const companies = [
  {
    name: 'Curastory',
    logo: curastoryLogo,
    logoWidth: '200px',
    description: 'Led by former ESPN associate Tiffany Kelly, Curastory allows athletes and creators to shoot and edit high-quality video, monetize their name, image, and likeness in powerful new ways, and distribute their content to their video channels.',
    link: 'https://curastory.co/'
  },
  {
    name: 'Elysian Park Planning',
    logo: elysianParkLogo,
    logoWidth: '70px',
    description: 'Founded by Janet Marie Smith, a designer and urban planner who has led the renovations of some of the world’s most legendary sports venues including Oriole Park at Camden Yards, Fenway Park, Dodger Stadium and now Stamford Bridge, EPP is transforming sports venues to create fan experiences rooted in magical spaces and revitalizing iconic landmarks across sports.',
    link: 'https://elysianparkplanning.com/'
  },
  {
    name: 'Fortë',
    logo: forteLogo,
    logoWidth: '180px',
    description: 'Led by former Wall Street executive Lauren Foundos, FORTË’s developed the fitness industry’s only turnkey, live and on-demand streaming solution, enabling gyms to offer a premium hybrid experience to their members and powering top brands like UFC GYM, YMCA, The Edge, 305 Fitness & the NFLPA.',
    link: 'https://www.goforte.fit/'
  },
  {
    name: 'The Gist',
    logo: gistLogo,
    logoWidth: '100px',
    description: 'Led by former college friends and sports fans Jacie deHoop, Ellen Hyslop, and Roslyn McLarty, The GIST is a community-driven digital media company, changing the way sports content and storytelling speak to underserved sports fans.',
    link: 'https://www.thegistsports.com/'
  },
  {
    name: 'HER Galaxy from Galaxy Racer',
    logo: galaxyRacerLogo,
    logoWidth: '200px',
    description: 'Led by serial entrepreneur, brand and community builder, Akemi Sue Fisher, HER Galaxy is a division of the global Galaxy Racer platform that seeks to empower women at the intersection of esports, gaming and culture.',
    link: 'https://www.hergalaxy.gg/'
  },
  {
    name: 'Ida Sports',
    logo: idaLogo,
    logoWidth: '150px',
    description: 'Co-founded by Laura Youngson, who led a group of women to the top of Mt. Kilimanjaro to play a Guinness world record-breaking football match to highlight gender inequalities in sport, Ida Sports exists to prioritize high-performance and comfortable footwear and cleats for female athletes who have traditionally been an afterthought in the sports industry.',
    link: 'https://www.idasports.com/'
  },
  {
    name: 'LOVB',
    logo: lovbLogo,
    logoWidth: '100px',
    description: 'Led by Katlyn Gao, a deeply experienced female-centric consumer brands executive, League One Volleyball is commercializing volleyball by creating a purpose and profit driven ecosystem rooted in a national network of junior clubs, with the vision to create the first, full-season indoor volleyball pro league in the US.',
    link: 'https://www.lovb.com/'
  },
  {
    name: 'Sportsbox AI',
    logo: sportsboxLogo,
    logoWidth: '220px',
    description: 'Led by former LPGA professional golfer Jeehae Lee, Sportsbox AI is an AI-powered single-camera 3D-motion capture technology company that brings real-time corrective feedback to help athletes achieve their goals in sports and fitness, starting in golf, using patent-pending 3D Motion Analysis and Kinematic AI technology.',
    link: 'https://www.sportsbox.ai/'
  },
  {
    name: 'Togethxr',
    logo: togethxrLogo,
    logoWidth: '200px',
    description: 'Founded by professional athletes Alex Morgan, Chloe Kim, Sue Bird, and Simone Manuel, and led by Co-founder and Chief Content Officer Jessica Robertson, Togethxr is a media and commerce company curating original content to elevate women’s voices in sports.',
    link: 'https://togethxr.com/'
  },
];

const Companies = () => {
  const renderCompaniesCards = () => {
    return companies.map((partner, index) => {
      return <CompaniesCard key={index} partner={partner} />;
    });
  };

  return (
    <section className={cx('companies')} id='companies'>
      <div className={cx('contentContainer')}>
        <div className={cx('header')}>
          <h2>Meet The Companies</h2>
        </div>
        <div className={cx('companiesList')}>{renderCompaniesCards()}</div>
      </div>
    </section>
  );
};

export default Companies;
