import React from 'react';
import classNames from 'classnames/bind';

import BJKELogo from './../../images/partners/bjke_logo.svg';
import EPVLogo from './../../images/partners/epv_logo.png';
import LADodgersLogo from './../../images/partners/la_dodgers_logo.png';
import RGAVenturesLogo from './../../images/partners/rga_ventures_logo.png';
import PartnerCard from './PartnerCard';

import * as styles from './Partners.module.scss';

const cx = classNames.bind(styles);

const partners = [
  {
    name: 'Billie Jean King Enterprises',
    logo: BJKELogo,
    logoWidth: '307px',
    description:
      'Billie Jean King Enterprises is an investment, consulting, and marketing firm that puts King’s philosophy and brand value to work by partnering with companies to challenge the status quo and create a level playing field.',
  },
  {
    name: 'Elysian Park Ventures',
    logo: EPVLogo,
    logoWidth: '246px',
    description:
      'Elysian Park Ventures is a domain-focused, stage-agnostic, and thesis-driven investment platform that invests in companies that are building the future of sports and that ignite growth in intersecting markets like culture, health, tech, and commerce.',
  },
  {
    name: 'Los Angeles Dodgers',
    logo: LADodgersLogo,
    logoWidth: '103px',
    description:
      'The Los Angeles Dodgers are one of the most innovative, successful, and cherished sports franchises in the world with a track record of breaking barriers both on and off the field.',
  },
  {
    name: 'R/GA Ventures',
    logo: RGAVenturesLogo,
    logoWidth: '242px',
    description:
      'R/GA Ventures works with startups and corporate partners to create a more human future. We do this by identifying disruptive and inspiring technologies and ideas, and supporting those ideas with the financial, creative, and relationship capital necessary to drive growth and returns.',
  },
];

const Partners = () => {
  const renderPartnerCards = () => {
    return partners.map((partner, index) => {
      return <PartnerCard key={index} partner={partner} />;
    });
  };

  return (
    <section className={cx('partners')} id='partners'>
      <div className={cx('contentContainer')}>
        <div className={cx('header')}>
          <h2>Our Partners</h2>
          <p>
            We believe that women are the biggest growth opportunity in sports.
            We also believe that no one has ever unlocked this combination’s
            full potential. We see Trailblazer Venture Studio as the key.
          </p>
        </div>
        <div className={cx('partnersList')}>{renderPartnerCards()}</div>
      </div>
    </section>
  );
};

export default Partners;
