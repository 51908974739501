import React from 'react';
import classNames from 'classnames/bind';
import * as styles from './PartnerCard.module.scss';

const cx = classNames.bind(styles);

const PartnerCard = ({ partner, thirds }) => {
  const { name, logo, logoWidth, description } = partner;

  return (
    <div className={cx('partnerCard', { thirds: thirds })}>
      <div className={cx('card')}>
        {logo ? (
          <img src={logo} alt={name} style={{ width: logoWidth }} />
        ) : (
          name
        )}
      </div>
      {name && <p className={cx('name')}>{name}</p>}
      {description ? <p className={cx('description')}>{description}</p> : null}
    </div>
  );
};

export default PartnerCard;
