import React from 'react';
import classNames from 'classnames/bind';
import * as styles from './Banner.module.scss';

const cx = classNames.bind(styles);

const Banner = () => {
  return (
    <section className={cx('banner')} id='what-we-do'>
      <div className={cx('content')}>
        <h2 className={cx('header')}>
          We accelerate your business’s growth through:
        </h2>
        <div className={cx('contentColumns')}>
          <div className={cx('column')}>
            <h3>Relationship capital</h3>
            <p>
              We connect you to senior leadership across the sports ecosystem,
              providing introductions to key players in the field, as well as
              access to opportunities specific to your business.
            </p>
          </div>
          <div className={cx('column')}>
            <h3>Creative capital</h3>
            <p>
              You’ll tap into world-class strategists, technologists, creatives,
              and consultants for brand, visual, and verbal design services, all
              customized for your venture’s unique needs.
            </p>
          </div>
          <div className={cx('column')}>
            <h3>Investment capital</h3>
            <p>
              You’ll find the financial resources and capital needed to expand
              and improve your products, service, and technology.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
